


















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';//获取专栏
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {

    }
})
export default class historicalStatistics extends Vue {
    [x: string]: any;
    public form: any = { pages: 1, size: 10, current: 1, total: 0, channelId: '', };
    public datacreateTime: any[] = [];
    private tableData: any[] = []
    public applications: any[] = [];
    public loading: Boolean = false;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.applicationsList();
        await this.getList();
    }

    public search() {
        this.form.current = 1
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            size: this.form.size,
            // current: this.form.current,
            current: 1,
            channelId: '',
        };
        this.datacreateTime = [];
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this, data_ = {};
        that.loading = true;
        if (that.datacreateTime != []) {
            this.form.endTime = that.datacreateTime[1]
            this.form.startTime = that.datacreateTime[0]
        } else {
            this.form.endTime = ''
            this.form.startTime = ''
        }
        data_ = {
            "current": that.form.current,
            "entity": {
                "agentId": that.form.channelId.toString(),
                "searchEndTime": this.form.endTime,
                "searchStartTime": this.form.startTime
            },
            "size": that.form.size
        }
        const res = await this.statisticsApi.historyList(data_);
        that.tableData = res.records;
        this.form.total = res.total;
        this.form.pages = res.pages;
        this.form.size = res.size;
        this.form.current = res.current;
        this.loading = false;
    }
    //获取专栏(这里是应用)
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res
    }
    public exportClick(row: any): void {
        var that = this, data_ = {}, str: any = '';
        if (that.tableData.length <= 0) {
            this.$message({
                type: 'error',
                message: '当前无下载内容！'
            })
            return;
        }
        if (that.datacreateTime.length > 0) {
            this.form.endTime = that.datacreateTime[1]
            this.form.startTime = that.datacreateTime[0]
        } else {
            this.form.endTime = ''
            this.form.startTime = ''
        }
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();
        var timeSend = year + '-' + month + '-' + day;
        if (row == 'all') {
            // 导出全部
            data_ = {
                "agentId": that.form.channelId.toString(),
                "searchEndTime": this.form.endTime,
                "searchStartTime": this.form.startTime
            }
            str = that.splicing(data_)
            this.statisticsApi.historyAllDownload(str, '历史消息'+timeSend);
            // window.open(document.location.origin + `/api/application/history-statis/download-list?` + str)
        } else {
            // 根据内容导出
            data_ = {
                "agentId": row.agentId,
                "searchEndTime": this.form.endTime,
                "searchStartTime": this.form.startTime
            }
            that.loading = false;
            str = that.splicing(data_)
            this.statisticsApi.historyDownload(str, row.agentName + '历史消息'+timeSend);
            // window.open(document.location.origin + `/api/application/history-statis/download?` + str)
        }
    }
    // 导出拼接
    public splicing(data: any) {
        var str = [], strString = '';
        for (var key in data) {
            // str+="&"+key+"="+ data_[key]
            str.push(key + "=" + data[key])
            console.log((key + ':' + data[key]))
        }
        strString = str.join('&')
        return strString;
    }
    // readingClick 阅读人数排序点击
    public readingClick(e: any): void {
        if (e.column.order == 'ascending') {
            // 向上排序
        } else
            if (e.column.order == 'descending') {
                // 向下排序
            } else {
                // 正常排序
            }
    }

    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
